import React from "react";
import {
  Button,
  Accordion,
  DottedButton,
  InputNumber,
  RadioButton,
  CheckBox,
} from "@bluesilodev/timhutcomponents";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import ArrowRight from "assets/icon/ArrowRight/ArrowRight";
import BarsSVG from "assets/icon/BarsSVG/BarsSVG";
import DeleteSVG from "assets/icon/DeleteSVG/DeleteSVG";
import FetchingAndError from "components/fetchingAndError";
import { useAlertApi } from "services/alert-api";
import {
  useFetchAttendanceSettingQuery,
  useAddAttendanceSettingMutation,
} from "store/apis/attendanceSettingApi";

const CustomInputNumber = styled.div`
  div {
    // max-width: 600px !important;
  }
`;

const AttendanceSetting = () => {
  // Redux State
  const { currentRole } = useSelector((state) => state.userData);
  // Formik
  const formik = useFormik({
    initialValues: {
      earlyPunchIn: 0,
      earlyPunchOut: 0,
      punchInDispen: 0,
      punchOutDispen: 0,
      selectOvertimeType: "",
      dailyOvertime: 0,
      weeklyOvertime: 0,
      roundingSettings: [],
      multiplierSettings: [],
      setToMax: false,
    },
    validationSchema: Yup.object().shape({
      earlyPunchIn: Yup.number().required("Early Punch In is required"),
      earlyPunchOut: Yup.number().required("Early Punch Out is required"),
      punchInDispen: Yup.number().required("Punch In Dispen is required"),
      punchOutDispen: Yup.number().required("Punch Out Dispen is required"),
      selectOvertimeType: Yup.string(),
      dailyOvertime: Yup.number().required("Daily Overtime is required"),
      weeklyOvertime: Yup.number().required("Weekly Overtime is required"),
      roundingSettings: Yup.array().of(
        Yup.object().shape({
          from: Yup.number(),
          to: Yup.number(),
          equal: Yup.number(),
        })
      ),
      multiplierSettings: Yup.array().of(
        Yup.object().shape({
          from: Yup.number(),
          to: Yup.number(),
          multiply: Yup.number(),
        })
      ),
      setToMax: Yup.boolean(),
    }),
    onSubmit: (values) => {
      addAttendanceSetting({
        earlyPunchIn: values.earlyPunchIn || 0,
        earlyPunchOut: values.earlyPunchOut || 0,
        punchInDispen: values.punchInDispen || 0,
        punchOutDispen: values.punchOutDispen || 0,
        selectOvertimeType: values.selectOvertimeType || "daily",
        dailyOvertime: values.dailyOvertime || 0,
        weeklyOvertime: values.weeklyOvertime || 0,
        overtimeRounding: values.roundingSettings || [],
        overtimeMultiplier: values.multiplierSettings.map((val) => ({
          from: val.from,
          to: val.to,
          equal: val.multiply,
        })),
      });
    },
  });
  // Redux Toolkit
  const {
    data: dataSetting,
    error: errorAttendanceSetting,
    isFetching: isFetchingAttendanceSetting,
  } = useFetchAttendanceSettingQuery(null, { refetchOnMountOrArgChange: true });
  const [addAttendanceSetting, addAttendanceSettingResults] =
    useAddAttendanceSettingMutation();

  useAlertApi(addAttendanceSettingResults);

  React.useEffect(() => {
    if (dataSetting && dataSetting.data) {
      formik.setFieldValue("earlyPunchIn", dataSetting.data.earlyPunchIn);
      formik.setFieldValue("earlyPunchOut", dataSetting.data.earlyPunchOut);
      formik.setFieldValue("punchInDispen", dataSetting.data.punchInDispen);
      formik.setFieldValue("punchOutDispen", dataSetting.data.punchOutDispen);
      formik.setFieldValue(
        "selectOvertimeType",
        dataSetting.data.selectOvertimeType
      );
      formik.setFieldValue("dailyOvertime", dataSetting.data.dailyOvertime);
      formik.setFieldValue("weeklyOvertime", dataSetting.data.weeklyOvertime);
      formik.setFieldValue(
        "roundingSettings",
        dataSetting.data.overtimeRounding
      );
      formik.setFieldValue(
        "multiplierSettings",
        dataSetting.data.overtimeMultiplier.map((val) => ({
          from: val.from,
          to: val.to,
          multiply: val.equal,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSetting]);

  return (
    <div className="main w-full relative mt-5 p-1">
      {["Admin"].includes(currentRole) && (
        <div className="flex justify-end">
          <div className="button-save">
            <Button
              label={"Save"}
              className={"w-[230px] h-[50px]"}
              style="solid"
              onClick={() => formik.submitForm()}
            />
          </div>
        </div>
      )}
      <FetchingAndError
        isFetching={isFetchingAttendanceSetting}
        isError={errorAttendanceSetting}
      >
        <FormikProvider value={formik}>
          <div className="mt-5 attendance">
            <Accordion
              title={<div>Attendance</div>}
              // inactive={!["Admin"].includes(currentRole)}
            >
              <div className="flex gap-2">
                <div className="w-4/12">
                  <CustomInputNumber>
                    <InputNumber
                      width="full"
                      label="Early Punch In Allowance"
                      name="early_punch_in_allowance"
                      onChange={(val) => {
                        formik.setFieldValue(
                          "earlyPunchIn",
                          isNaN(val) || !val ? 0 : val
                        );
                      }}
                      unit="Minute"
                      value={formik.values.earlyPunchIn}
                      disable={!["Admin"].includes(currentRole)}
                    />
                  </CustomInputNumber>
                </div>
                <div className="w-4/12">
                  <InputNumber
                    label="Punch Out In Allowance"
                    name="punch_out_in_allowance"
                    onChange={(val) => {
                      formik.setFieldValue(
                        "earlyPunchOut",
                        isNaN(val) || !val ? 0 : val
                      );
                    }}
                    unit="Minute"
                    value={formik.values.earlyPunchOut}
                    disable={!["Admin"].includes(currentRole)}
                  />
                </div>
              </div>

              <div className="flex gap-2 pt-2">
                <div className="w-4/12">
                  <InputNumber
                    label="Punch In Dispensation"
                    name="punch_in_dispensation"
                    onChange={(val) => {
                      formik.setFieldValue(
                        "punchInDispen",
                        isNaN(val) || !val ? 0 : val
                      );
                    }}
                    unit="Minute"
                    value={formik.values.punchInDispen}
                    disable={!["Admin"].includes(currentRole)}
                  />
                </div>
                <div className="w-4/12">
                  <InputNumber
                    label="Punch Out Dispensation"
                    name="Punch_out_dispensation"
                    onChange={(val) => {
                      formik.setFieldValue(
                        "punchOutDispen",
                        isNaN(val) || !val ? 0 : val
                      );
                    }}
                    unit="Minute"
                    value={formik.values.punchOutDispen}
                    disable={!["Admin"].includes(currentRole)}
                  />
                </div>
              </div>
            </Accordion>
          </div>
          <div className="mt-5 overtime">
            <Accordion
              title={<div>Overtime</div>}
              // inactive={!["Admin"].includes(currentRole)}
            >
              <div className="flex items-center">
                <div
                  className="p-2 w-[270px]"
                  onChange={() => {
                    formik.setFieldValue("selectOvertimeType", "daily");
                  }}
                >
                  <RadioButton
                    name="radio_button"
                    options={[
                      {
                        id: "daily",
                        label: "Daily Work Time After",
                      },
                    ]}
                    value={
                      ["Admin"].includes(currentRole)
                        ? formik.values.selectOvertimeType
                        : ""
                    }
                    disable={!["Admin"].includes(currentRole)}
                  />
                </div>
                <div className="w-3/12 p-2">
                  <InputNumber
                    label="Daily Work Time After"
                    name="daily_work_time_after"
                    unit="Hour"
                    value={formik.values.dailyOvertime}
                    disable={!["Admin"].includes(currentRole)}
                    onChange={(val) => {
                      formik.setFieldValue(
                        "dailyOvertime",
                        isNaN(val) || !val ? 0 : val
                      );
                    }}
                  />
                </div>
                <span className="pl-6">Per Day</span>
              </div>
              <div className="flex items-center">
                <div
                  className="p-2 w-[270px]"
                  onChange={() => {
                    formik.setFieldValue("selectOvertimeType", "weekly");
                  }}
                >
                  <RadioButton
                    name="radio_button"
                    options={[
                      {
                        id: "weekly",
                        label: "Weekly Work Time After",
                      },
                    ]}
                    value={
                      ["Admin"].includes(currentRole)
                        ? formik.values.selectOvertimeType
                        : ""
                    }
                    disable={!["Admin"].includes(currentRole)}
                  />
                </div>
                <div className="w-3/12 p-2">
                  <InputNumber
                    label="Weekly Work Time After"
                    name="weekly_work_time_after"
                    unit="Hour"
                    value={formik.values.weeklyOvertime}
                    disable={!["Admin"].includes(currentRole)}
                    onChange={(val) => {
                      formik.setFieldValue(
                        "weeklyOvertime",
                        isNaN(val) || !val ? 0 : val
                      );
                    }}
                  />
                </div>
                <span className="pl-6">Per Week</span>
              </div>
              <div className="text-red-500">
                {formik.errors.selectOvertimeType}
              </div>
            </Accordion>
          </div>

          <div className="mt-5 overtime-settings">
            <Accordion title={<div>Overtime Rounding Settings</div>}>
              <div className="pr-2 font-semibold mt-2">
                <p className="text-sm">Balance Generated</p>
              </div>

              <FieldArray
                name="roundingSettings"
                render={(arrayHelpers) => (
                  <React.Fragment>
                    {formik.values.roundingSettings.map((setting, index) => (
                      <React.Fragment key={index}>
                        <div className="flex">
                          <div className="flex items-center">
                            <div className="py-2 pr-2 w-[300px]">
                              <InputNumber
                                label="From (Minutes)"
                                name={`overtime_rounding_from`}
                                unit="Min"
                                value={setting.from}
                                disable={!["Admin"].includes(currentRole)}
                                onChange={(val) => {
                                  formik.setFieldValue(
                                    `roundingSettings[${index}].from`,
                                    isNaN(val) || !val ? 0 : val
                                  );
                                }}
                              />
                            </div>
                            <div className="flex flex-col item-center mx-2 ">
                              <button className="border-2 border-slate-500 rounded-[50%] p-2 my-2 hover:bg-gray-200">
                                <div className="w-6 h-6 flex justify-center items-center">
                                  <ArrowRight />
                                </div>
                              </button>
                            </div>
                            <div className="p-2 w-[300px]">
                              <InputNumber
                                label="To (Minutes)"
                                name={`overtime_rounding_to`}
                                unit="Min"
                                value={setting.to}
                                disable={!["Admin"].includes(currentRole)}
                                onChange={(val) => {
                                  formik.setFieldValue(
                                    `roundingSettings[${index}].to`,
                                    isNaN(val) || !val ? 0 : val
                                  );
                                }}
                              />
                            </div>
                            <div className="flex flex-col item-center mx-2 ">
                              <button className="border-2 border-slate-500 rounded-[50%] p-2 my-2 hover:bg-gray-200">
                                <div className="w-6 h-6 flex justify-center items-center">
                                  <BarsSVG />
                                </div>
                              </button>
                            </div>
                            <div className="p-2 w-[300px]">
                              <InputNumber
                                label="Equal (Minutes)"
                                name={`overtime_rounding_equal`}
                                unit="Min"
                                value={setting.equal}
                                disable={!["Admin"].includes(currentRole)}
                                onChange={(val) => {
                                  formik.setFieldValue(
                                    `roundingSettings[${index}].equal`,
                                    isNaN(val) || !val ? 0 : val
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="pl-2 py-1">
                              {["Admin"].includes(currentRole) && (
                                <button
                                  className="border-2 border-slate-500 rounded-[50%] p-2 my-2 hover:bg-gray-200"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <div className="w-6 h-6 flex items-center justify-center">
                                    <DeleteSVG />
                                  </div>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}

                    {["Admin"].includes(currentRole) && (
                      <div className="flex items-center ">
                        <div className="w-[1100px] py-1">
                          <DottedButton
                            text="Add Overtime Round Settings"
                            onClick={() =>
                              arrayHelpers.push({ from: 0, to: 0, equal: 0 })
                            }
                          />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
              />
            </Accordion>
          </div>

          <div className="mt-5 overtime-multipler">
            <Accordion title={<div>Overtime Multipler</div>}>
              <div className="pr-2 font-semibold mt-2">
                <p className="text-sm">Balance Generated</p>
              </div>

              <FieldArray
                name="multiplierSettings"
                render={(arrayHelpers) => (
                  <React.Fragment>
                    {formik.values.multiplierSettings.map(
                      (item, index, array) => (
                        <React.Fragment key={index}>
                          <div className="flex">
                            <div className="flex items-center">
                              <div className="py-2 pr-2 w-[300px]">
                                <InputNumber
                                  label="From (Hours)"
                                  name={`overtime_multipler_from`}
                                  unit="Hour"
                                  value={item.from}
                                  disable={!["Admin"].includes(currentRole)}
                                  onChange={(val) => {
                                    formik.setFieldValue(
                                      `multiplierSettings[${index}].from`,
                                      isNaN(val) || !val ? 0 : val
                                    );
                                  }}
                                />
                              </div>
                              <div className="flex flex-col item-center mx-2 ">
                                <button className="border-2 border-slate-500 rounded-[50%] p-2 my-2 hover:bg-gray-200">
                                  <div className="w-6 h-6 flex justify-center items-center">
                                    <ArrowRight />
                                  </div>
                                </button>
                              </div>
                              <div className="p-2 w-[300px]">
                                <InputNumber
                                  label="To (Hours)"
                                  name={`overtime_multipler_to`}
                                  unit="Hour"
                                  disable={
                                    !["Admin"].includes(currentRole) ||
                                    (index === array.length - 1 &&
                                      formik.values.setToMax)
                                  }
                                  value={item.to}
                                  onChange={(val) => {
                                    formik.setFieldValue(
                                      `multiplierSettings[${index}].to`,
                                      isNaN(val) || !val ? 0 : val
                                    );
                                  }}
                                />
                              </div>
                              <div className="flex flex-col item-center mx-2 ">
                                <button className="border-2 border-slate-500 rounded-[50%] p-2 my-2 hover:bg-gray-200">
                                  <div>
                                    <div className="w-6 h-6 flex justify-center items-center">
                                      <BarsSVG />
                                    </div>
                                  </div>
                                </button>
                              </div>
                              <div className="p-2 w-[300px]">
                                <InputNumber
                                  label="Multiply"
                                  name={`overtime_multipler_multiply`}
                                  unit={""}
                                  pluralize={false}
                                  value={item.multiply}
                                  disable={!["Admin"].includes(currentRole)}
                                  onChange={(val) => {
                                    formik.setFieldValue(
                                      `multiplierSettings[${index}].multiply`,
                                      isNaN(val) || !val ? 0 : val
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="flex items-center">
                              <div className="pl-2 py-1 flex items-center">
                                {index === array.length - 1 && (
                                  <div className="pr-2">
                                    <CheckBox
                                      name="radio_button"
                                      label={"Set To Max"}
                                      disabled={
                                        !["Admin"].includes(currentRole)
                                      }
                                      onChange={(e) => {
                                        formik.setFieldValue("setToMax", e);
                                        if (e) {
                                          formik.setFieldValue(
                                            `multiplierSettings[${index}].to`,
                                            99
                                          );
                                        }
                                      }}
                                      value={formik.values.setToMax}
                                      options={[
                                        {
                                          id: "option1",
                                          label: "Set To Max",
                                        },
                                      ]}
                                    />
                                  </div>
                                )}
                                {["Admin"].includes(currentRole) && (
                                  <button
                                    className="border-2 border-slate-500 rounded-[50%] p-2 my-2 hover:bg-gray-200 "
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <div className="w-6 h-6 flex items-center justify-center">
                                      <DeleteSVG />
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    )}

                    {["Admin"].includes(currentRole) && (
                      <div className="flex items-center ">
                        <div className="w-[1100px] py-1">
                          <DottedButton
                            text="Add Overtime Multipler"
                            onClick={() => {
                              arrayHelpers.push({
                                from: 0,
                                to: 0,
                                multiply: 0,
                              });
                              formik.setFieldValue("setToMax", false);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
              />
            </Accordion>
          </div>
        </FormikProvider>
      </FetchingAndError>
    </div>
  );
};

export default AttendanceSetting;
