import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Sidebar, Header } from "@bluesilodev/timhutcomponents";
import { Outlet, useNavigate, useLocation, NavLink } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { setLogoutReducer, setRoleReducer } from "store/slices/userSlice";
import { useFetchAppsQuery } from "store/apis/commonApi";

import UserSVG from "assets/icon/UserSVG/UserSVG";
import FiHeartSVG from "assets/icon/FiHeartSVG/FiHeartSVG";
import Profile from "assets/images/profile.jpeg";
import AttendanceApprovalSVG from "assets/icon/AttendanceApprovalSVG/AttendanceApprovalSVG";
import AttendanceDataSVG from "assets/icon/AttendanceDataSVG/AttendanceDataSVG";
import AttendanceOvertimeSVG from "assets/icon/AttendanceOvertimeSVG/AttendanceOvertimeSVG";
import AttendanceSettingSVG from "assets/icon/AttendanceSettingSVG/AttendanceSettingSVG";
import ChevronLeftSVG from "assets/icon/ChevronLeftSVG/ChevronLeftSVG";
import axios from "services/axios";
import { alertError } from "utils/alert";
import pkg from "../../package.json";

const Layout = () => {
  const dispatch = useDispatch();
  const { currentUser, currentRole } = useSelector((state) => state.userData);
  const [blockNonAdmin, setBlockNonAdmin] = useState(undefined);

  const location = useLocation();
  const navigate = useNavigate();

  if (currentUser?.role?.includes("SuperAdmin")) {
    window.location.href = "/employee/super/organization";
  }

  /* DELETE SOON
  const changeRole = () => {
    const roleValue = role === "Employee" ? "Admin" : "Employee";
    localStorage.setItem("role", roleValue);
    navigate(0);
  };
  */

  const [apps, setApps] = useState([]);

  const { data: resApp } = useFetchAppsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (resApp?.data) {
      setApps(resApp.data?.data);
    }
  }, [resApp]);

  useEffect(() => {
    if (location.pathname !== "/attendance-setting") {
      // check if leave setting configured
      const localStorageSettingReady = localStorage.getItem(
        "settingReadyAttendance"
      );
      if (localStorageSettingReady !== "true") {
        axios.get(`/api/attendance-setting`).then((response) => {
          if (!response.data.data) {
            if (!currentUser.role.includes("Admin")) {
              setBlockNonAdmin(true);
              return;
            }
            navigate("/attendance-setting");
            alertError("Error", "Please fill attendance setting first");
          } else {
            localStorage.setItem("settingReadyAttendance", "true");
          }
        });
      }
    }
  }, [location.pathname]);

  const onClickLogout = () => {
    dispatch(setLogoutReducer());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentRole");

    // using window as we used microservice frontend and need to redirect to workbench
    window.location.assign("/dashboard");
  };

  const onClickSwitch = () => {
    dispatch(setRoleReducer());
  };

  const onClickApp = (link) => {
    window.location.assign(link);
  };

  const onClickMyAccount = (link) => {
    window.location.assign(`${link}/myAccount`);
  };

  const changeRole = (currentRole) => {
    localStorage.setItem("role", currentRole);
    navigate(0);
  };

  const allMenu = [
    {
      label: (
        <NavLink
          to={"/"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            (location.pathname === "/" ||
              location.pathname.startsWith("/attendance-data")) &&
            "bg-orange-500 text-white"
          }`}
        >
          <AttendanceDataSVG
            color={`${
              location.pathname === "/" ||
              location.pathname.startsWith("/attendance-data")
                ? "white"
                : "black"
            }`}
          />
          <h1 className="text-sm font-semibold">Attendance Data</h1>
        </NavLink>
      ),
    },
    {
      label: (
        <NavLink
          to={"/attendance-approval"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname.startsWith("/attendance-approval") &&
            "bg-orange-500 text-white"
          }`}
          isActive={(match, location) => {
            return location.pathname.startsWith(match.url);
          }}
        >
          <AttendanceApprovalSVG
            className="h-[24px] w-[20px] mx-[2px]"
            color={`${
              location.pathname.startsWith("/attendance-approval")
                ? "white"
                : "black"
            }`}
          />
          <h1 className="text-sm font-semibold">Attendance Approval</h1>
        </NavLink>
      ),
    },
    {
      label: (
        <NavLink
          to={"/attendance-overtime"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname.startsWith("/attendance-overtime") &&
            "bg-orange-500 text-white"
          }`}
          isActive={(match, location) => {
            return location.pathname.startsWith(match.url);
          }}
        >
          <AttendanceOvertimeSVG
            className="h-[24px] w-[20px] mx-[2px]"
            color={`${
              location.pathname.startsWith("/attendance-overtime")
                ? "white"
                : "black"
            }`}
          />
          <h1 className="text-sm font-semibold">Attendance Overtime</h1>
        </NavLink>
      ),
    },
    {
      label: (
        <NavLink
          to={"/attendance-setting"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/attendance-setting" &&
            "bg-orange-500 text-white"
          }`}
        >
          <AttendanceSettingSVG
            className="h-[24px] w-[20px] mx-[2px]"
            color={`${
              location.pathname === "/attendance-setting" ? "white" : "black"
            }`}
          />
          <h1 className="text-sm font-semibold">Attendance Setting</h1>
        </NavLink>
      ),
    },
  ];

  const getTitle = () => {
    const pathSegments = location.pathname.split("/").filter(Boolean);

    switch (pathSegments[0]) {
      case "attendance-data" || undefined:
        return (
          <div className="flex flex-row items-center justify-center">
            {pathSegments.length > 1 ? (
              <React.Fragment>
                <span onClick={() => navigate(-1)}>
                  <ChevronLeftSVG className="w-[20px] h-[20px] mr-2" />
                </span>
                Attendance Data Details
              </React.Fragment>
            ) : (
              "Attendance Data"
            )}
          </div>
        );

      case "attendance-approval":
        return (
          <div className="flex flex-row items-center justify-center">
            {pathSegments.length > 1 ? (
              <React.Fragment>
                <span onClick={() => navigate(-1)}>
                  <ChevronLeftSVG className="w-[20px] h-[20px] mr-2" />
                </span>
                Attendance Approval Details
              </React.Fragment>
            ) : (
              "Attendance Approval"
            )}
          </div>
        );

      case "attendance-overtime":
        return (
          <div className="flex flex-row items-center justify-center">
            {pathSegments.length > 1 ? (
              <React.Fragment>
                <span onClick={() => navigate(-1)}>
                  <ChevronLeftSVG className="w-[20px] h-[20px] mr-2" />
                </span>
                Attendance Overtime Details
              </React.Fragment>
            ) : (
              "Attendance Overtime"
            )}
          </div>
        );

      case "attendance-setting":
        return (
          <div className="flex flex-row items-center justify-center">
            Attendance Setting
          </div>
        );

      default:
        return (
          <div className="flex flex-row items-center justify-center">
            Attendance
          </div>
        );
    }
  };

  return (
    <div className="flex w-full min-h-dvh">
      <div className="min-w-[300px] h-full overflow-y-auto z-[9]">
        <Sidebar
          menuItems={allMenu}
          className={"bg-[#F2EFFF]"}
          onLogoClick={() => navigate("/")}
          version={pkg.version}
        />
      </div>

      <div className="flex flex-col flex-1 max-h-screen p-5 overflow-y-auto">
        <div className="p-1">
          {apps.length > 0 && (
            <Header
              title={getTitle()}
              apps={apps}
              switchDisabled={currentUser?.role?.length < 2}
              userData={{
                name: currentUser?.userName,
                role: currentRole,
                language: "English",
                // switchRole: currentRole === "Admin" ? "User" : "Admin",
                switchRole:
                  currentRole === "Admin"
                    ? currentUser?.role.includes("Supervisor")
                      ? "Supervisor"
                      : "Employee"
                    : currentUser?.role.includes("Admin")
                    ? "Admin"
                    : currentRole,
                image:
                  currentUser?.photo?.length > 0
                    ? currentUser?.photo[0].link
                    : "",
              }}
              onSwitch={onClickSwitch}
              onClickMyAccount={onClickMyAccount}
              onClickLogout={onClickLogout}
              onClickApp={onClickApp}
            />
          )}
        </div>

        <div className="">
          {blockNonAdmin ? (
            <React.Fragment>
              <div className="flex flex-col items-center h-full">
                <XMarkIcon width="300" />
                <div className="font-semibold">
                  Currently unavailable. Please contact admin for configuration.
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Outlet />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;
